
// Utilities
import { mapMutations } from "vuex";

export default {
  props: {
    opened: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    name: "",
    links: [
      {
        to: "/admin",
        icon: "mdi-human",
        text: "管理者",
      },
      {
        to: "/hospital",
        icon: "mdi-hospital-building",
        text: "病院",
      },
      {
        to: "/v2/staff",
        icon: "mdi-doctor",
        text: "スタッフ",
      },
      {
        to: "/notifications",
        icon: "mdi-bell",
        text: "お知らせ",
      },
    ],
    linksInMovie: [
      {
        to: "/v2/department",
        icon: "mdi-office-building",
        text: "診療科",
      },
      {
        to: "/v2/approach",
        icon: "mdi-content-cut",
        text: "治療法",
      },
      {
        to: "/v2/genre",
        icon: "mdi-heart",
        text: "ジャンル",
      },
      {
        to: "/v2/video",
        icon: "mdi-monitor",
        text: "動画",
      },
    ],
    // TODO: ここをコメントアウトすることでことでドロワーに出てくるようにする
    // linksInScenario: [
    //   {
    //     to: "/v2/scenarioInformation",
    //     icon: "mdi-animation-play",
    //     text: "動画2.0",
    //   },
    // ],
    role: "",
  }),

  computed: {
    inputValue: {
      get() {
        return this.$store.state.drawer;
      },
      set(val) {
        this.setDrawer(val);
      },
    },
  },

  methods: {
    ...mapMutations(["setDrawer", "toggleDrawer"]),
  },
};
